<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <q-product-library-admin-submenu></q-product-library-admin-submenu>
        <!-- Charts-->
        <v-row justify="space-between" align="stretch" class="mx-5">
            <v-col cols="12">
                <q-carrier-data-table></q-carrier-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QCarrierDataTable from '@/components/datatables/QCarrierDataTable.vue'
import QProductLibraryAdminSubmenu from '@/components/navigation/Submenus/QProductLibraryAdminSubmenu.vue'

export default {
    name: "Carriers",
    data() {
        return {

        };
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    components: {
        QCarrierDataTable,
        QProductLibraryAdminSubmenu
    }
};

</script>
