<template>
    <QDynamicSubmenu
        :base-url="baseUrl"
        :default-title="title"
        :default-buttons="buttons"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QProductLibraryAdminSubmenu",
    data() {
        return {
            title: "Product Library",
            baseUrl: '/admin',
        }
    },
    computed: {
        buttons: function() {
            const buttons = [
                {
                    label: "Products",
                    to: "/admin/products",
                    icon: "fas fa-arrow-alt-circle-right",
                },
                {
                    label: "Carriers",
                    to: "/admin/carriers",
                    icon: "fas fa-arrow-alt-circle-right",
                },
            ];

            if (this.hasPermission('manage:Carriers')) {
                const buttonsManage = [
                    {
                        label: "New Product",
                        to: "/admin/products/new",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-new-product"
                    },
                    {
                        label: "New Carrier",
                        to: "/admin/carriers/new",
                        icon: "fas fa-arrow-alt-circle-right",
                    },
                ];
                buttons.push(...buttonsManage);
            }

            return buttons;
        }
    },
    components: {
        QDynamicSubmenu,
    },
}

</script>

<style></style>
